import { Button } from "@components/library";
import { COLORS, WINDOW_DIMENSIONS } from "@constants";
import googleCalendar from "@images/icons/google_calendar.svg";
import ical from "@images/icons/ical.svg";
import outlook from "@images/icons/outlook.svg";
import dayjs from "dayjs";
import { cloneElement, useRef } from "react";
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc";
import styled from "styled-components";

const AddToCalendarButton = ({ date, title, description }) => {
  if (!date) return null;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleBlur = () => {
    if (containerRef.current) {
      setTimeout(() => {
        (containerRef.current?.children[0].children[0] as HTMLButtonElement).click();
      }, 200);
    }
  };

  const startDate = dayjs(date).startOf("day");
  const endDate = dayjs(startDate).add(1, "day").startOf("day");

  return (
    <Container ref={containerRef} onBlur={handleBlur}>
      <AddToCalendar
        event={{
          title,
          description,
          duration: 24,
          endDatetime: dayjs(endDate).format("YYYYMMDD"),
          startDatetime: dayjs(startDate).format("YYYYMMDD"),
        }}
        items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK, SHARE_SITES.ICAL]}
      />
    </Container>
  );
};

// AddToCalendarHOC passes entire <a /> components with logic that is abstracted
// away from the consumers (like us), so we use React.cloneElement to be able to
// take the read-only react component passed by AddToCalendarHOC and pass in the
// output of getDropdownComponent (gives us more control over the icons/text in
// each dropdown item and doesn't lose the calendar logic that is not exposed).
const getDropdownComponent = (key) => {
  switch (key.toLowerCase()) {
    case "google":
      return (
        <>
          <DropdownItemIcon src={googleCalendar} /> Google Calendar
        </>
      );
    case "ical":
      return (
        <>
          <DropdownItemIcon src={ical} /> {key}
        </>
      );
    case "outlook":
      return (
        <>
          <DropdownItemIcon src={outlook} /> {key}
        </>
      );
  }
};

const ButtonWrapper = ({ onClick }) => (
  <Button type="button" width="100%" variant="secondary" onClick={onClick} iconName="Calendar">
    Add to Calendar
  </Button>
);

const DropdownWrapper = ({ children }) => (
  <CalendarDropdown>
    {children.map((link) => (
      <CalendarDropdownItem key={link.key}>
        {cloneElement(link, [], getDropdownComponent(link.key))}
      </CalendarDropdownItem>
    ))}
  </CalendarDropdown>
);

const AddToCalendar = AddToCalendarHOC(ButtonWrapper, DropdownWrapper);

export default AddToCalendarButton;

const Container = styled.div`
  position: relative;
`;
const CalendarDropdown = styled.ul`
  list-style-type: none;
  background-color: ${COLORS.WHITE};
  margin: auto;
  padding: 10px;
  font-size: 14px;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0px 10px 22px rgba(29, 71, 123, 0.08), 0px 2px 12px rgba(29, 71, 123, 0.08);
  width: 222px;
  position: absolute;
  top: 44px;
  left: calc(50%);
  transform: translateX(-50%);
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    align-items: center;
    justify-content: center;
    line-height: 1.2em;
  }
`;
const CalendarDropdownItem = styled.li`
  padding: 0px 10px;
  align-self: stretch;
  height: 38px;
  margin: auto;
  cursor: pointer;
  &:hover {
    background: ${COLORS.NEUTRAL_100};
    border-radius: 4px;
  }
  a {
    color: ${COLORS.BLACK};
    vertical-align: text-top;
    padding: 3px 0px 3px 5px;
    display: inline-block;
    width: 100%;
    height: 100%;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      text-decoration: none;
    }
  }
`;
const DropdownItemIcon = styled.img`
  padding-right: 8px;
`;
