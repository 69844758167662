import RfpCard from "@components/RfpCard/RfpCard";
import { BlankModal, Icon, Pagination, SelectableDropdown } from "@components/library";
import { RfpUnsubscribeModal } from "@components/modals";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { useSearchParams } from "@hooks/router";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getMarketplaceRequests } from "@requests/requests";
import { useQuery } from "@tanstack/react-query";
import { RequestPartnerTypes, RequestableType } from "@tsTypes/requests";
import { sortMarketplaceRequests } from "@utils/requestUtils";
import { toastErrorHandler } from "@utils/requests";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import KeywordSignUpModal from "../../components/modals/KeywordSignUpModal";
import RespondToRequestsFilters from "./RespondToRequestsFilters";
import { Columns, ContentColumn, FilterColumn } from "./sharedFilterStyles";

interface Props {
  currentPageNum: number;
  setCurrentPageNum: (newPageNum: number) => void;
}

export enum SortBy {
  RELEVANCE = "relevance",
  LAUNCH_DATE = "launch_date",
  DEADLINE = "deadline",
}
export const SORT_BY_OPTIONS = [
  { value: SortBy.RELEVANCE, label: "Recommended" },
  { value: SortBy.LAUNCH_DATE, label: "Recently added" },
  { value: SortBy.DEADLINE, label: "Deadline" },
] as const;

export const MAX_REQUEST_CARD_WIDTH = 586;
const NON_FEATURED_CARDS_PER_PAGE = 15;
const VIEW_ALL_PARAM = "view_all";

const RespondToRequests = ({ currentPageNum, setCurrentPageNum }: Props) => {
  const [isRfpUnsubscribeModalOpen, setIsRfpUnsubscribeModalOpen] = useState(false);
  const [isKeywordsModalOpen, setIsKeywordsModalOpen] = useState(false);

  const [sortBy, setSortBy] = useState<(typeof SORT_BY_OPTIONS)[number]>(SORT_BY_OPTIONS[0]);
  const [searchFilter, setSearchFilter] = useState<string[]>([]);
  const [requestTypesFilter, setRequestTypesFilter] = useState<RequestableType[]>([]);
  const [partnerTypesFilter, setPartnerTypesFilter] = useState<RequestPartnerTypes[]>([]);
  const allFilters = [searchFilter, requestTypesFilter, partnerTypesFilter];

  const isTablet = useSelector((state: RootState) => state.window.isTablet);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const canViewAllParam = searchParams.get(VIEW_ALL_PARAM) === "true";
  const canViewAll =
    canViewAllParam || localStorage?.getItem(VIEW_ALL_PARAM) === "true" || currentUser.id;

  useEffect(() => {
    if (!canViewAllParam) return;

    // Save the view_all param to local storage so this session can always view all RFPs even without the param
    if (localStorage && !localStorage.getItem(VIEW_ALL_PARAM))
      localStorage.setItem(VIEW_ALL_PARAM, "true");
  }, [canViewAllParam]);

  const { data, isLoading } = useQuery(
    ["getMarketplaceRequests", ...allFilters],
    () =>
      getMarketplaceRequests({
        searchFilter,
        requestTypesFilter,
        partnerTypesFilter,
      }),
    {
      onError: toastErrorHandler,
      onSuccess: () => setCurrentPageNum(1),
    }
  );
  const requests = data?.requests ?? [];
  const requestScores = data?.scores ?? {};
  const totalCount = requests.length;

  useEffect(() => {
    if (location.search.includes("unsubscribe=") && location.search.includes("nid=")) {
      setIsRfpUnsubscribeModalOpen(true);
    }
    if (location.search.includes("modal=keywords")) {
      setIsKeywordsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (Number(totalCount) > 0) {
      scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPageNum]);

  const beginningOfPage = (currentPageNum - 1) * NON_FEATURED_CARDS_PER_PAGE;
  const endOfPage = beginningOfPage + NON_FEATURED_CARDS_PER_PAGE;

  sortMarketplaceRequests({ requests, scores: requestScores, sort: sortBy.value });

  const filters = (
    <RespondToRequestsFilters
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      requestTypesFilter={requestTypesFilter}
      setRequestTypesFilter={setRequestTypesFilter}
      partnerTypesFilter={partnerTypesFilter}
      setPartnerTypesFilter={setPartnerTypesFilter}
      sortBy={sortBy}
      setSortBy={setSortBy}
      totalCount={totalCount}
    />
  );

  return (
    <>
      <BlankModal
        onClose={() => {
          setIsKeywordsModalOpen(false);
        }}
        isOpen={isKeywordsModalOpen}
        width="540px"
      >
        <KeywordSignUpModal
          onSubmit={() => {
            setIsKeywordsModalOpen(false);
          }}
        />
      </BlankModal>
      <RfpUnsubscribeModal
        isOpen={isRfpUnsubscribeModalOpen}
        onClose={() => setIsRfpUnsubscribeModalOpen(false)}
      />
      <Container>
        {!isTablet && (
          <Description>
            Find requests from corporate R&D teams.
            <br />
            Submit a non-confidential response in about 30 minutes.
          </Description>
        )}
        <Columns>
          <FilterColumn>{filters}</FilterColumn>
          <ContentColumn>
            <>
              {!isTablet && (
                <CountAndSort>
                  <div>
                    Sort by:
                    <SelectableDropdown
                      options={SORT_BY_OPTIONS}
                      value={sortBy}
                      onChange={setSortBy}
                      menuHorizontalPosition="right"
                    />
                  </div>
                </CountAndSort>
              )}
              {isTablet && filters}
            </>
            {isLoading ? (
              <Loader active inline="centered" style={{ marginTop: 100 }} />
            ) : (
              <>
                <div ref={scrollToRef}>
                  {requests.length > 0 ? (
                    <CardsContainer data-testid="request-cards">
                      {requests.slice(beginningOfPage, endOfPage).map((request) => (
                        <RfpCard
                          key={request.id}
                          request={request}
                          fromHref={window.location.pathname + window.location.search}
                        />
                      ))}
                    </CardsContainer>
                  ) : (
                    <NoResultsContainer>
                      <NoResultsHeader>
                        <Icon name="Search" color={COLORS.NEUTRAL_500} />
                        Sorry, no results.
                      </NoResultsHeader>
                      <NoResultsText>
                        Try changing your search terms or adjusting your filters.
                      </NoResultsText>
                    </NoResultsContainer>
                  )}
                </div>
                <Pagination
                  currentPage={currentPageNum}
                  setCurrentPage={(pageNumber: number) =>
                    canViewAll
                      ? setCurrentPageNum(pageNumber)
                      : dispatch(toggleLoginModal({ open: true }))
                  }
                  totalPages={Math.ceil(Number(totalCount) / NON_FEATURED_CARDS_PER_PAGE)}
                  marginTop="56px"
                />
              </>
            )}
          </ContentColumn>
        </Columns>
      </Container>
    </>
  );
};

export default RespondToRequests;

// These are also used in FindPartners.tsx

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 48px;
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    margin-top: 32px;
  }
`;
export const Description = styled.h2`
  ${FONTS.HEADING_3_REGULAR};
  margin-bottom: 8px;
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    display: none;
  }
`;
const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
  max-width: 2350px;
  ${WINDOW_DIMENSIONS.EXTRA_WIDE_MEDIA_QUERY} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  ${WINDOW_DIMENSIONS.DESKTOP_MEDIA_QUERY} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    grid-template-columns: minmax(0, 1fr);
  }
`;
const CountAndSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  margin-bottom: 24px;

  & > div {
    display: flex;
    align-items: center;
    gap: 6px;
    ${FONTS.REGULAR_2}
  }
`;
const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 128px 0px;
`;
const NoResultsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const NoResultsText = styled.div`
  ${FONTS.REGULAR_2}
  color: ${COLORS.NEUTRAL_500};
  text-align: center;
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    max-width: 240px;
  }
`;
