import { Icon } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";
import { proposalyPagePath } from "@routes/utils";
import { FundingPartial } from "@tsTypes/fundings/_funding";
import { ScientistAttachmentProposal } from "@tsTypes/index";
import { PatentPartial } from "@tsTypes/patents/_patent";
import { PublicationPartial } from "@tsTypes/publications/_publication";
import { RequestableType } from "@tsTypes/requests";
import { Company, Foundation, GovernmentOrganization, University } from "@tsTypes/schema";
import { t } from "@utils/i18n";
import { REQUESTABLE_TO_ICON_NAME } from "@utils/requestUtils";
import dayjs from "dayjs";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  contribution: PublicationPartial | FundingPartial | PatentPartial | ScientistAttachmentProposal;
  isExpandable?: boolean;
  size?: "sm" | "md" | "lg";
}

function formatDate(date: string) {
  return dayjs(date).format("MMM YYYY");
}

const ScientistContribution = ({ contribution, isExpandable = true, size = "lg" }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  let { url } = contribution as any;
  if ("requestable_type" in contribution) {
    url = proposalyPagePath(contribution.slug!, contribution.requestable_type);
  }

  let title = contribution.title;
  let additionalInfo: ReactNode;
  let date: string | null = null;
  let content: string | null = null;
  // Publication
  if ("publication_name" in contribution) {
    additionalInfo = (
      <PublicationName isExpanded={isExpanded} hasUrl={Boolean(url)}>
        <Icon name="Publication" color={COLORS.NEUTRAL_400} />
        <span>{contribution.publication_name}</span>
      </PublicationName>
    );
    if (contribution.date) date = formatDate(contribution.date);
    content = contribution.abstract;

    // Funding
  } else if ("amount" in contribution) {
    const { sponsor } = contribution;
    if (sponsor || contribution.other_sponsor) {
      title = `${
        (sponsor as Foundation)?.foundation_name ??
        (sponsor as GovernmentOrganization)?.org_name ??
        (sponsor as Company)?.company_name ??
        (sponsor as University)?.name ??
        contribution.other_sponsor
      } - ${title}`;
    }
    if (contribution.amount) {
      additionalInfo = (
        <Detail>
          <Icon name="Funding" color={COLORS.NEUTRAL_400} />$
          {Intl.NumberFormat().format(Math.floor(contribution.amount))}
        </Detail>
      );
    }
    if (contribution.date) date = formatDate(contribution.date);
    if (contribution.end_date) date += ` - ${formatDate(contribution.end_date)}`;
    content = contribution.description;

    // Patent
  } else if ("filing_date" in contribution) {
    if (contribution.status) {
      additionalInfo = (
        <Detail>
          <Icon name="Status" color={COLORS.NEUTRAL_400} />
          Status: {t(["models.patent.status", contribution.status])}
        </Detail>
      );
    }
    if (contribution.filing_date) {
      date = `Filing Date: ${formatDate(contribution.filing_date)}`;
    }
    content = contribution.abstract;

    // Proposal
  } else if ("requestable_type" in contribution) {
    const { requestable_type, request, completed, first_completed_at } = contribution;

    if (!title) title = `RFP: ${request.title}`;

    let requestLabel = "RFP";
    switch (requestable_type) {
      case RequestableType.REQUEST_FOR_EXPERT:
        requestLabel = "Request for Experts";
        break;
      case RequestableType.OPEN_CALL:
        requestLabel = "Open Call";
        break;
      case RequestableType.REQUEST_FOR_STARTUP:
        requestLabel = "Startup Program";
        break;
    }
    additionalInfo = (
      <Detail>
        <Icon
          name={REQUESTABLE_TO_ICON_NAME[requestable_type]}
          color={COLORS.NEUTRAL_400}
          size="sm"
        />
        {requestLabel}: {request.title}
      </Detail>
    );

    if (completed && first_completed_at) {
      date = formatDate(first_completed_at);
    } else if (!completed) {
      date = "Not submitted (Draft)";
    }

    content = contribution.preview;
  }

  return (
    <Container size={size}>
      <Preview
        type="button"
        onClick={() => isExpandable && setIsExpanded(!isExpanded)}
        size={size}
        isExpandable={isExpandable}
      >
        <TopRow size={size}>
          <Title
            as={(() => {
              if (url && "requestable_type" in contribution) return Link;
              else if (url) return "a";
              return "div";
            })()}
            href={
              // eslint-disable-next-line no-nested-ternary
              url?.startsWith("http")
                ? url
                : url?.startsWith("10.")
                ? `https://doi.org/${url}`
                : `https://${url}`
            }
            to={url}
            target="_blank"
            size={size}
            isExpanded={isExpanded}
            onClick={(e) => url && e.stopPropagation()}
          >
            {title}
          </Title>
          {isExpandable && content && <Icon name={isExpanded ? "Chevron Up" : "Chevron Down"} />}
        </TopRow>
        {isExpanded && <Content>{content}</Content>}
        <Details isExpanded={isExpanded}>
          {additionalInfo}
          {date && (
            <Detail>
              <Icon name="Calendar" color={COLORS.NEUTRAL_400} />
              {date}
            </Detail>
          )}
        </Details>
      </Preview>
    </Container>
  );
};

export default ScientistContribution;

const Container = styled.div`
  width: 100%;
  min-width: 0;
  padding: ${({ size }) => (size === "sm" ? "4px 8px" : "16px")};
  background: ${({ size }) => (size === "md" ? COLORS.NEUTRAL_200 : COLORS.NEUTRAL_50)};
  border-radius: 8px;
`;
const Preview = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0;
  ${FONTS.MEDIUM_3};
  text-align: left;
  background: none;
  border: none;
  &:hover {
    text-decoration: none;
    cursor: ${({ isExpandable }) => (isExpandable ? "pointer" : "default")};
  }
  &:focus {
    outline: none;
    text-decoration: none;
  }
`;
const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  ${({ size }) => size === "sm" && "height: 40px"};
  margin-bottom: 4px;
  width: 100%;
`;
const Title = styled.div`
  ${({ size }) => (size === "sm" ? FONTS.MEDIUM_3 : FONTS.MEDIUM_2)};
  ${({ isExpanded, size }) => {
    if (size === "lg" && !isExpanded) return STYLES.ONE_LINE_ALT;
    if (size === "sm") return STYLES.TWO_LINES;
    if (size === "md") return STYLES.THREE_LINES;
  }};
  color: ${COLORS.BLACK};
  ${({ as: _as }) =>
    _as !== "div" &&
    `
  &:hover,
  &:focus {
    color: ${COLORS.BLACK};
    text-decoration: underline;
    outline: none;
  }
  `}
`;
const Details = styled.div`
  display: flex;
  align-items: ${({ isExpanded }) => (isExpanded ? "flex-start" : "center")};
  gap: 24px;
  color: ${COLORS.NEUTRAL_400};
  width: 100%;
`;
const Detail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const PublicationName = styled(Detail)`
  ${({ isExpanded }) => isExpanded && "align-items: flex-start"};
  max-width: calc(100% - 110px);
  & > span {
    ${({ isExpanded }) => isExpanded && "margin-top: 2px"};
    ${({ isExpanded, hasUrl }) => (hasUrl || !isExpanded) && STYLES.ONE_LINE_ALT};
  }
`;
const Content = styled.div`
  margin: 12px 0;
  ${FONTS.REGULAR_3};
`;
