import { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days2";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.locale("en");
dayjs.extend(localeData);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsBusinessDays);

export const daysUntilDate = (date) => {
  return dayjs(date).diff(Date.now(), "days");
};

export const dayDropdownOptions = (month?: string, year?: string): DropdownOption[] => {
  let daysInMonth = 31;
  if (month && year) {
    daysInMonth = dayjs(`${year}-${month}`, "YYYY-MM").daysInMonth();
  } else if (month) {
    daysInMonth = dayjs(`2020-${month}`, "YYYY-MM").daysInMonth(); // 2020 is a leap year
  }

  const daysInMonthArray: number[] = [];
  for (let i = 1; i <= daysInMonth; i += 1) {
    daysInMonthArray.push(i);
  }

  return daysInMonthArray.map((day) => {
    return {
      label: String(day),
      value: String(day),
    };
  });
};

export const MONTH_DROPDOWN_OPTIONS: DropdownOption[] = dayjs.monthsShort().map((month, index) => {
  return {
    label: month,
    value: String(index + 1),
  };
});

const yearOptions: number[] = [];
for (let i = new Date().getFullYear(); i > 1950; i -= 1) {
  yearOptions.push(i);
}
export const YEAR_DROPDOWN_OPTIONS: DropdownOption[] = yearOptions.map((year) => {
  return {
    label: String(year),
    value: String(year),
  };
});

export const formatDateToEastern = (date: string, format: string) => {
  return dayjs(date).tz("America/New_York").format(format);
};
