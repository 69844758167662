import { User } from "@tsTypes/schema";
import { UniversityExpertise } from "@tsTypes/universityProfiles";

export const sortByHasProfilePicture = <T extends Pick<User, "image"> & Record<string, unknown>>(
  users: T[],
  imageSize: "small" | "medium" | "large" = "medium"
): T[] => {
  return users.sort((a, b) => {
    return Number(Boolean(b.image?.[imageSize].url)) - Number(Boolean(a.image?.[imageSize].url));
  });
};

export const hasInvalidUniversityExpertise = (expertise: string): boolean => {
  return expertise.split(",").some((e) => {
    return !(Object.values(UniversityExpertise) as string[]).includes(e);
  });
};
