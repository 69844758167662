import { Button, Icon } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { RootState } from "@redux/store";
import { daysUntilDate } from "@utils/dateUtils";
import { t } from "@utils/i18n";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SubscriptionEndedModal from "./SubscriptionEndedModal";

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const SUBSCRIPTION_ENDING_WARNING_DAYS = 60;

const SubscriptionWarning = () => {
  const [isSubscriptionEndedModalOpen, setIsSubscriptionEndedModalOpen] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const subscription = useSelector((state: RootState) => state.company.subscription);

  const daysUntilSubscriptionEnds = daysUntilDate(subscription?.end_at);

  useEffect(() => {
    setIsSubscriptionEndedModalOpen(daysUntilSubscriptionEnds < 0);
  }, [daysUntilSubscriptionEnds]);

  if (
    !subscription?.end_at ||
    daysUntilSubscriptionEnds > SUBSCRIPTION_ENDING_WARNING_DAYS ||
    subscription?.has_upcoming_subscription
  )
    return null;

  const isAdmin = currentUser.profile_info.is_admin;
  const bannerMessage = t(
    [
      "components.subscription_warning",
      daysUntilSubscriptionEnds < 0 ? "ended" : "ending_soon",
      isAdmin ? "admin" : "non_admin",
    ],
    {
      subscriptionEndDate: dayjs(subscription?.end_at).format("MMMM D, YYYY"),
    }
  );

  return (
    <>
      {daysUntilSubscriptionEnds < 0 && (
        <SubscriptionEndedModal
          isOpen={isSubscriptionEndedModalOpen}
          onClose={() => setIsSubscriptionEndedModalOpen(false)}
          setIsSubscriptionEndedModalOpen={setIsSubscriptionEndedModalOpen}
          bannerMessage={bannerMessage}
        />
      )}
      <SubscriptionEndBanner>
        <Icon name="Attention" size="lg" color={COLORS.WHITE} margin="0 5px 0 0" />
        {bannerMessage}
        {isAdmin && (
          <Link to={"/contact"} target="_blank" style={{ backgroundColor: COLORS.WHITE }}>
            <Button type="button" variant="destructive-ghost" size="sm">
              {t("components.subscription_warning.cta")}
            </Button>
          </Link>
        )}
      </SubscriptionEndBanner>
    </>
  );
};

const SubscriptionEndBanner = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  margin: 0px;
  padding: 0 ${WINDOW_DIMENSIONS.PAGE_SIDE_PADDING}px;
  background-color: ${COLORS.RED};
  color: ${COLORS.WHITE};
  ${FONTS.REGULAR_2};

  & > a {
    margin-left: auto;
  }

  ${TABLET_MEDIA_QUERY} {
    width: 100%;
    height: 80px;
    left: 0;
    padding: 0 18px;
  }
`;

export default SubscriptionWarning;
