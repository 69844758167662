import LoginModal from "@components/LoginSignUpModal/LoginModal";
import toast from "@components/Toast";
import { Button, KeywordsDropdown } from "@components/library";
import { COLORS, FONTS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import haloLogo from "@images/logos/halo_logo.svg";
import { setCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { skipUpdateUserKeywords, updateUserKeywords } from "@requests/users";
import { profile_page_path } from "@routes/routes";
import { marketplacePath } from "@routes/utils";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import { setPrevUrlCookie } from "@utils/authUtils";
import { t } from "@utils/i18n";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { USER_ROLES } from "src/constants/users";
import styled from "styled-components";

interface Props {
  onSubmit?: () => void;
}

const KeywordSignUpModal = (props: Props) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [modalState, setModalState] = useState<"login" | "keywords">("login");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [disciplines, setDisciplines] = useState<string[]>(currentUser.disciplines);
  const [areasOfExpertise, setAreasOfExpertise] = useState<string[]>(
    currentUser.areas_of_expertise
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    track(SegmentEventName.Click, {
      ui_component: "Add to Profile",
      react_component: "KeywordSignUp",
    });

    setIsSubmitting(true);

    let response: any;
    try {
      response = await updateUserKeywords(currentUser.id, {
        disciplines,
        areasOfExpertise,
      });
      setIsSubmitting(false);
    } catch (err) {
      appsignal.sendError(err, (span) => {
        span.setAction("KeywordSignUp#handleSubmit");
        span.setTags({
          currentUserId: currentUser.id,
        });
      });
    }

    if (response?.data?.success) {
      dispatch(
        setCurrentUser({
          ...currentUser,
          disciplines,
          areas_of_expertise: areasOfExpertise,
          has_updated_taxonomy: true,
        })
      );

      if (currentUser.role === USER_ROLES.scientist) {
        history.push(profile_page_path(currentUser.profile_id));
      } else {
        history.push("/");
      }

      props.onSubmit?.();
    }
  };

  const onSkip = () => {
    track(SegmentEventName.Click, {
      ui_component: "Skip",
      react_component: "KeywordSignUp",
    });

    try {
      skipUpdateUserKeywords(currentUser.id);
    } catch (err) {
      console.error(err);
    }

    history.push(marketplacePath());

    toast.success(
      <p>
        {t("pages.keyword_sign_up.toast.text")}
        <Link
          to={`/profile/${currentUser.first_name}-${currentUser.last_name}-${currentUser.id}`}
          style={{ textDecoration: "underline", color: "white" }}
        >
          {t("pages.keyword_sign_up.toast.link")}
        </Link>
      </p>
    );
  };

  useEffect(() => {
    if (currentUser.id) {
      if (currentUser.role === USER_ROLES.sponsor) {
        history.push("/reviewer_dashboard");
        return;
      }

      setModalState("keywords");
    } else if (modalState === "login") {
      setPrevUrlCookie();
    }
  }, [currentUser.id]);

  if (modalState === "keywords") {
    return (
      <Modal>
        <Top>
          <Logo>
            <Link to="/">
              <img src={haloLogo} />
            </Link>
          </Logo>
          <Header>
            {currentUser.role === UserRole.SCIENTIST
              ? t("pages.keyword_sign_up.header.scientist")
              : t("pages.keyword_sign_up.header.admin")}
          </Header>
          <Form onSubmit={handleSubmit}>
            <SingleInputContainer>
              <Title>
                {t([
                  "pages.keyword_sign_up.disciplines_label",
                  currentUser.role === UserRole.SCIENTIST ? "scientist" : "university_admin",
                ])}
              </Title>
              <KeywordsDropdown
                keywordType="discipline"
                targetType={
                  currentUser.role === UserRole.SCIENTIST ? "scientist" : "university_admin"
                }
                value={disciplines}
                onChange={setDisciplines}
                isPortal
              />
            </SingleInputContainer>
            {currentUser.role === USER_ROLES.scientist && (
              <SingleInputContainer>
                <Title>{t("pages.keyword_sign_up.expertise_label")}</Title>
                <KeywordsDropdown
                  keywordType="area_of_expertise"
                  targetType="scientist"
                  value={areasOfExpertise}
                  onChange={setAreasOfExpertise}
                  isPortal
                />
              </SingleInputContainer>
            )}
          </Form>
        </Top>
        <Bottom>
          <ButtonContainer>
            <Button
              size="md"
              type="submit"
              variant="ghost"
              disabled={isSubmitting}
              onClick={onSkip}
              data-testid="keyword-signup-skip-button"
            >
              {t("pages.keyword_sign_up.skip")}
            </Button>
            <Button
              size="md"
              type="submit"
              disabled={isSubmitting}
              onClick={handleSubmit}
              data-testid="keyword-signup-add-to-profile-button"
            >
              {t("pages.keyword_sign_up.primary_button")}
            </Button>
          </ButtonContainer>
        </Bottom>
      </Modal>
    );
  }

  return (
    <LoginModal
      header={
        <Logo margin="12px auto 0">
          <Link to="/">
            <img src={haloLogo} />
          </Link>
        </Logo>
      }
      title={<SignInTitle>{t("pages.keyword_sign_up.sign_in_title")}</SignInTitle>}
      bottom={
        <>
          Don&rsquo;t have an account?&nbsp;
          <SignUp to="/get-started">Sign up</SignUp>
        </>
      }
    />
  );
};

const { TABLET_MEDIA_QUERY, MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

const Modal = styled.div`
  border-radius: 16px;

  background-color: ${COLORS.WHITE};
  box-shadow: ${STYLES.SHADOW_D};

  ${TABLET_MEDIA_QUERY} {
    max-width: 95%;
    width: unset;
  }

  ${MOBILE_MEDIA_QUERY} {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 100%;

  text-align: center;
`;

const Header = styled.div`
  margin: 40px auto;

  ${FONTS.HEADING_5_REGULAR}

  text-align: center;

  max-width: 400px;

  ${MOBILE_MEDIA_QUERY} {
    ${FONTS.HEADING_4_SEMIBOLD}
  }
`;

const SignInTitle = styled.div`
  ${FONTS.HEADING_5_REGULAR}

  width: 400px;

  text-align: center;

  ${MOBILE_MEDIA_QUERY} {
    max-width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`;

const Top = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 48px;

  ${TABLET_MEDIA_QUERY} {
    padding: 40px 24px;
  }
`;

const Bottom = styled.div`
  display: inline-block;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  padding: 16px;

  border-top: 1px solid ${COLORS.NEUTRAL_300};

  ${FONTS.SEMIBOLD_2}
  text-align: center;

  ${MOBILE_MEDIA_QUERY} {
    width: 100%;
    margin-top: 15px;

    color: ${COLORS.NEUTRAL_500};

    ${FONTS.SEMIBOLD_1}
    text-align: left;
  }
`;

const SignUp = styled(Link)`
  color: ${COLORS.BLACK};

  text-decoration: underline;
  ${FONTS.REGULAR_2}

  cursor: pointer;

  ${MOBILE_MEDIA_QUERY} {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 48px;
    margin-top: 12px;

    border-radius: 3px;
    border-style: solid;
    border-width: 2px;

    color: ${COLORS.HALO_BLUE};
    ${FONTS.SEMIBOLD_1}

    text-decoration: none;
  }
`;

const SingleInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const Title = styled.label`
  display: flex;
  flex-direction: column;

  width: 100%;

  ${FONTS.SEMIBOLD_2};

  margin-bottom: 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;

  width: 100%;
`;

export default KeywordSignUpModal;
